import 'doctor/styles/consultation-details.css';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import {
  cancelAppointmentByDoctorRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { getDoctorAppointmentDetailsState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import Alerts from 'shared/components/Alerts';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useTranslation } from 'react-i18next';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import dayjs from 'dayjs';
import React from 'react';
import { getPrivateMediaFileRequest } from '../../../shared/redux/actions/UngroupedActions';

const DoctorConsultationDetailsTabInfo = (props) => {
  const { t } = useTranslation();
  const { templateType = 'future' } = props;
  const dispatch = useDispatch();

  const {
    date = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    id,
    appointmentType = { type: '' },
    realDurationAsString,
    duration,
    serverStatus,
    status,
    priceGrossAsString,
    conclusion,
    prescription,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  const cancelAppointment = (e) => {
    e.preventDefault();
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.cancelAppointment')}`,
      () => dispatch(cancelAppointmentByDoctorRequest({ id, templateType })),
      () => {},
    );
  };
  const joinAppointment = (e) => {
    e.preventDefault();
    if (!Utils.rejoinableAppointment(serverStatus, date, duration)) {
      Alerts.simpleAlert(t('info'), t('alerts.tooLate'));
      return;
    }
    dispatch(
      getAppointmentDetailsRequest({
        id,
        updateLocal: true,
        alsoTwilio: true,
      }),
    );
  };
  const renderButton = () => {
    if (templateType === 'future') {
      if (Utils.rejoinableAppointment(serverStatus, date, duration)) {
        return (
          <Link to="/" onClick={joinAppointment} className="green-button start-consultation-btn">
            <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
            {t('appointments.startAppointment')}
          </Link>
        );
      }
      if (appointmentType.type !== 'free_talk') {
        return (
          <button
            type="button"
            onClick={cancelAppointment}
            className="white-button cancel-consultation-btn"
          >
            {t('appointments.cancelAppointment')}
          </button>
        );
      }
      return null;
    }
    return null;
  };

  const openMedicalRecordFile = (externalId) => dispatch(getPrivateMediaFileRequest(externalId));
  return (
    <>
      {renderButton()}
      <div className="subtitle">{t('details')}</div>
      <div className="details-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('dateAndTime')}</div>
            <div className="details">{translatedFullDate(date, true, true)}</div>
          </ListGroup.Item>
          {serverStatus !== 'cancelled' && realDurationAsString > 0 && (
            <ListGroup.Item>
              <div className="title">{t('duration')}</div>
              <div className="details">{`${
                templateType === 'future' ? duration : realDurationAsString
              } min`}</div>
            </ListGroup.Item>
          )}
          {templateType === 'past' && (
            <>
              <ListGroup.Item>
                <div className="title">{t('state')}</div>
                <div className="details">{status}</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="title">{t('wallet.initialAppointmentFee')}</div>
                <div className="details">{priceGrossAsString}</div>
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </div>
      {serverStatus === 'completed' && (
        <>
          <div className="subtitle">{t('medicalRecommendation')}</div>
          <div className="details-box">
            <ListGroup>
              <div className="medical-recommendation">
                <div className="title">{t('symptoms')}</div>
                <div className="details">{conclusion?.symptoms}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('diagnosis')}</div>
                <div className="details">{conclusion?.diagnosis}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('knownAllergies')}</div>
                <div className="details">{conclusion?.knownAllergies}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('ongoingTreatments')}</div>
                <div className="details">{conclusion?.ongoingTreatments}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('treatment')}</div>
                <div className="details">{conclusion?.treatment}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('recommendation')}</div>
                <div className="details">{conclusion?.recommendations}</div>
              </div>
              <div className="medical-recommendation">
                <div className="title">{t('doctorApp.documents')}</div>
                <div className="details">
                  {conclusion.files.map((medicalRecord) => {
                    return (
                      <div key={medicalRecord.id} className="document">
                        <button
                          type="button"
                          onClick={() => openMedicalRecordFile(medicalRecord.id)}
                        >
                          <CustomIcon
                            className="custom-icon document"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Document"
                          />
                          <div className="info">
                            <div className="name">
                              {Utils.shortenText(
                                Utils.capitalizeFirstLetter(medicalRecord.name),
                                30,
                              )}
                            </div>
                            <div className="data">{medicalRecord.createdAt}</div>
                          </div>
                          <CustomIcon
                            className="custom-icon arrow"
                            color={Colors.darkGrey}
                            size="24"
                            icon="Right_pointer"
                          />
                        </button>
                      </div>
                    );
                  })}
                  {prescription?.media && (
                    <div key={prescription.media.id} className="document">
                      <button
                        type="button"
                        onClick={() => openMedicalRecordFile(prescription.media.id)}
                      >
                        <CustomIcon
                          className="custom-icon document"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Document"
                        />
                        <div className="info">
                          <div className="name">{t('prescription.title')}</div>
                          <div className="data">{prescription.media.createdAt}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon arrow"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Right_pointer"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </ListGroup>
          </div>
        </>
      )}
    </>
  );
};

DoctorConsultationDetailsTabInfo.defaultProps = {
  match: {},
  templateType: 'future',
};
DoctorConsultationDetailsTabInfo.propTypes = {
  templateType: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};
export default DoctorConsultationDetailsTabInfo;
